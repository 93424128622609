import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
  Hem: "/",
  "Analytics Consulting": "/se/analysrådgivning",
  "Google Analytics Setup": "/se/google-analytics-setup"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-analytics-setup"
);

const AnalyticsSetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Konfigurera Google Analytics från grunden! [${currentYear} Guide]`}
        description="Guide om hur man konfigurerar en modern Google Analytics-setup. Målinställning, händelsespårning och bästa praxis. Tutorial från certifierad Google Analytics-konsult."
        lang="se"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
        canonical="/se/google-analytics-setup"
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="man arbetar med Google Analytics-setup på laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Guide för Google Analytics-setup</H>
        <p>
          En av de första uppgifterna för en ny webbplats är att sätta upp Google Analytics. Efter all tid och
          ansträngning du lagt ner på att bygga en webbplats vill du självklart mäta och analysera användarnas beteende.
          Du kanske har frågor som:
        </p>

        <ul>
          <li>Hur presterar mitt innehåll?</li>
          <li>Vilken produktkategori är mest populär?</li>
          <li>Hur många konverteringar utlöste min Facebook-kampanj?</li>
          <li>Tittar besökarna faktiskt på videon jag producerade?</li>
          <li>Finns det några sidor som presterar särskilt dåligt?</li>
          <li>Dela besökarna något på sociala medier?</li>
          <li>Avbryter folk under kassan?</li>
        </ul>

        <p>
          Alla antaganden om användarbeteende samt kampanjprestanda måste bekräftas. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% av alla webbplatser på internet använder Google Analytics</a> för att göra det. Faktum är att det har en <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">marknadsandel på 84% på marknaden för trafikanalysverktyg</a>, så du behöver inte oroa dig för att du väljer fel analysverktyg.
        </p>
        <p>
          Ett webbanalysverktyg gör att du kan fatta beslut baserat på data istället för att gissa. Sådana beslut kan till exempel handla om vilken typ av innehåll du ska investera i eller hur mycket budget du bör spendera på annonskampanjer.
        </p>
        <p>
          Därför är det av väsentlig betydelse att sätta upp Google Analytics professionellt, så att du spårar rätt KPI:er för din analys. Med en sådan setup på plats kommer du ha massor av mätvärden att dyka ner i.
        </p>
        <H as="h2">Typer av Google Analytics-setup</H>
        <p>
          Webbplatsens typ är en nyckelfaktor för en Google Analytics-setup:
          <br />
          Driver du en onlinebutik eller säljer produkter? <br />
          Då är
          e-commerce analytics vägen att gå. Det är mer produkt- och transaktionsorienterat och kommer
          ge dig värdefulla insikter du inte vill missa.
        </p>
        <p>
          Om du driver en innehållsfokuserad webbplats om ditt varumärke, då är en typisk Google Analytics-spårningssetup det
          rätta valet. <br />
          Du kan spåra användarinteraktioner med <Link to="/se/händelsespårning">händelsespårning</Link> och övervaka
          viktiga webbplats-KPI:er med{" "}
          <Link to="/se/google-analytics-setup#how-to-set-up-goals-in-google-analytics">Google Analytics-mål</Link>.
        </p>

        <p>
          E-commerce analytics-setup kräver mer data om produkter och transaktioner. Det är därför konfigurationen
          är lite mer komplex och involverar anpassad JavaScript för att bygga ett så kallat{" "}
          <Link to="/se/data-lager">data layer</Link>
          . <br />Ett data layer är i grunden en lagring av data som innehåller information om varje sida. Google
          Analytics kommer sedan att känna igen och använda det när det är tillgängligt.
        </p>
        <p>
          Oroa dig inte om du inser att du behöver sätta upp Google Analytics för en e-commerce-webbplats. Du kan
          aktivera e-commerce-funktionalitet senare och även förbättra data layer när du är redo att göra det.{" "}
          <br />
          Fram till dess kommer e-commerce-rapporter att vara tomma, men andra rapporter kommer att fungera bra.
        </p>

        <H as="h2">Var får man Google Analytics-spårningskoden?</H>
        <p>
          Besök{" "}
          <a href="https://analytics.google.com" target="_blank" rel="noopener">
            analytics.google.com
          </a>{" "}
          och klicka på <strong>Signup</strong>-knappen för att skapa ett Google Analytics-konto. Du får Google
          Analytics-spårningskoden när du följer stegen nedan.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
          alt="Google Analytics anmälningsskärm"
          className="article-img"
        />
        <ul>
          <li>
            Efter att du klickar på <strong>signup</strong>-knappen, fortsätt och välj ett fastighetsnamn och URL
            följt av din bransch och tidszon.{" "}
          </li>
          <li>
            Jag rekommenderar att <strong>avmarkera alla alternativ för datadelning</strong>, om du inte har full kontroll
            över din integritetspolicy. Men det innebär att Google <strong>inte</strong> kommer att dela publikdemografi och
            intresserapporter med dig. <br />
            Om du vill använda dessa rapporter kan du behöva uppdatera din integritetspolicy. Detta är för att informera
            användarna att du delar data om deras surfbeteende med Google.
          </li>
          <li>
            När du är klar får du ditt <strong>Google Analytics-spårnings-ID</strong> (t.ex.{" "}
            <em>UA-141112345-1</em>). Tillsammans med det får du kodsnutten att lägga in i källkoden på alla dina sidor.
          </li>
        </ul>
        <H as="h2">Hur implementerar man Google Analytics?</H>
        <p>Det finns generellt två sätt att implementera Google Analytics:</p>

        <ol>
          <li>
            Du klistrar in Google Analytics-spårningskoden i källkoden på alla sidor. Du kan göra detta sida för sida
            eller så klistrar du in det i en gemensam mallfil. Det kan vara header, navigation eller footer som alla sidor
            har gemensamt.
            <br />
            <strong>Jag rekommenderar inte</strong> detta sätt att implementera Google Analytics, om du inte vill göra det
            snabbt och enkelt och aldrig röra analyssetupen igen. <br />
            De flesta spårningssetuper görs snabbare och enklare med ett <Link to="/se/tagghantering">tag management system</Link>. Det är en enklare och också
            mer flexibel process, så det är värt att introducera ännu <em>ett verktyg</em> i stacken.
          </li>

          <li>
            Du använder ett tag management system, som Google Tag Manager, vilket är ganska populärt.
            <br />
            Det finns andra alternativ som <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> eller{" "}
            <em>Matomo</em> (öppen källkod) också. <br />
            Generellt sett tjänar de alla samma syfte och är mycket lika. Du kan implementera Google Analytics med ett
            icke-Google tag management system utan problem. Google Tag Manager råkar bara vara det som alla enkelt kan få
            tillgång till och har mest resurser tillgängliga online.
            <br />
            Om du vill lära dig mer om det, kolla in min <Link to="/se/google-tag-manager-setup">Google Tag Manager tutorial</Link>.
          </li>
        </ol>

        <H as="h2">Hur implementerar man Google Analytics med Google Tag Manager?</H>
        <p>
          För <strong>implementeringen av Google Analytics via Google Tag Manager,</strong> följ dessa steg:
        </p>

        <ul>
          <li>
            Besök{" "}
            <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
              tagmanager.google.com
            </a>{" "}
            och skapa ett konto.{" "}
          </li>{" "}
        </ul>
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-create-account.png"
          alt="Google Tag Manager skapa konto"
          className="article-img"
        />

        <ul>
          <li>
            Med kontot skapar du en container för din webbplats, så se till att välja <strong>Web</strong>{" "}
            för dess användning:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
          alt="Google Tag Manager webcontainer skapande skärmbild"
          className="article-img"
        />

        <p>
          därefter kommer du att få <strong>Google Tag Manager-kodsnutten</strong> för implementering:
        </p>

        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager kodsnutt"
          className="article-img"
        />

        <p>
          Lägg till den första delen i <code>&lt;head&gt; </code>
          taggen på alla sidor på din webbplats. Den andra delen läggs in i <code>&lt;body&gt;</code>-taggen.
        </p>
        <p>Efter att Google Tag Manager (GTM)-snutten har lagts till kan analysinställningen konfigureras på nolltid!</p>

        <ul>
          <li>
            I GTM, klicka på <strong>tags</strong> i vänster navigering och <strong>lägg till en ny tag</strong>. En
            overlay kommer att glida in från sidan och du kan ge taggen ett <strong>namn</strong>, t.ex.{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Klicka sedan på taggkonfigurationsrutan:</li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-create-new-tag.png"
            alt="Google Tag Manager skapa ny tag"
            className="article-img"
          />

          <li>
            En lista med taggtyper kommer att glida in på skärmen. <br />
            Välj <strong>Google Analytics - Universal Analytics</strong>-taggen och starta konfigurationen.
          </li>

          <ImgContainerFixed width="462px">
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
              alt="Google Tag Manager skapa Google Analytics-tag"
              className="article-img"
            />
          </ImgContainerFixed>

          <li>
            Behåll <strong>Track Type</strong> som <strong>Pageview</strong>. <br />
          </li>
          <li>
            Skapa sedan en <strong>ny Google Analytics-inställningsvariabel.</strong>
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
            alt="Google Tag Manager inställningsvariabel för Google Analytics skärmdump"
            className="article-img"
          />

          <li>
            För att konfigurera inställningsvariabeln, ge den först ett <strong>namn</strong>. Därefter klistrar du in{" "}
            <strong>Google Analytics Tracking ID</strong> (<em>UA-141112345-1</em>), som du{" "}
            <Link to="#where-to-get-the-google-analytics-tracking-code">fick tidigare</Link>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
            alt="Google Tag Manager Google Analytics setup tracking ID skärmdump"
            className="article-img"
          />

          <li>
            Klicka på <strong>spara</strong>.
          </li>
          <li>
            Tillbaka i taggkonfigurationen, klicka på avsnittet <strong>utlösning</strong> för att välja en utlösare.
          </li>

          <li>
            Välj <strong>alla sidor</strong>, eftersom vi vill att Google Analytics kodsnutt ska köras på alla sidor.
          </li>

          <li>
            <strong>Spara</strong> både utlösaren och taggkonfigurationen och återgå till Google Tag Manager
            översikten.
          </li>

          <li>
            Tryck på den blå <strong>skicka in</strong>-knappen i det övre högra hörnet. Ge din första containerversion ett{" "}
            <strong>namn</strong> och klicka på <strong>publicera</strong>. <br />
            Nu har du framgångsrikt implementerat Google Analytics med Google Tag Manager!
          </li>
        </ul>

        <p>
          Du bör se data komma in i din Google Analytics-egendom efter ungefär en timme eller så.
          <br />
          Du kan också titta på realtidsrapporten för att se om det redan kommer in data från ditt
          nuvarande besök.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
          alt="Google Analytics översikt av realtidsrapport"
        />

        <p>
          Märker du att du kan implementera andra verktyg eller skript och utlösa dem på <strong>alla sidor</strong>, på bara några
          minuter? <br /> Jag hoppas detta får dig att inse hur enkelt och kraftfullt ett tag management system är.
        </p>

        <H as="h2">Hur ställer man in mål i Google Analytics?</H>
        <p>
          Nu när du får besöksdata är det dags att ställa in <strong>mål i Google Analytics</strong>.
        </p>
        <p>Chansen är stor att du säljer något på din webbplats eller att det finns ett formulär som folk kan fylla i. </p>
        <p>
          Normalt, efter en beställning, landar användarna på en <strong>tacksida</strong>. Den brukar säga tack för beställningen och listar några beställningsdetaljer. <br /> Titta i adressfältet och se vilken URL den sidan har.{" "}
        </p>
        <p>Till exempel kan det se ut så här:</p>
        <p>
          <code>https://www.dinsite.com/tack.php</code>{" "}
        </p>
        <p>
          Låt oss ställa in ett mål för denna adress och räkna hur ofta denna sida besöks för att indikera hur många beställningar
          som har gjorts.
        </p>

        <ul>
          <li>
            Gå till Google Analytics <strong>administrationsområde</strong> med en översikt över ditt konto, din egendom och
            vy. Klicka på <strong>mål</strong> inom avsnittet <strong>vy</strong>.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-admin-area-goals.png"
          alt="Google Analytics administrationsområde mål"
          className="article-img"
        />

        <ul>
          <li>
            <strong>Lägg till</strong> ett nytt mål och välj <strong>anpassad</strong> i måluppsättningen. Därefter{" "}
            <strong>fortsätt</strong>.
          </li>
          <li>
            Ge ditt mål ett <strong>namn</strong>, till exempel <em>Nyhetsbrev prenumeration</em> eller{" "}
            <em>Orderformulär mottaget</em> och välj att målet ska vara en <strong>destination</strong>. Därefter{" "}
            <strong>fortsätt</strong>.
          </li>
          <li>
            Under <strong>destination</strong> är <strong>lika med</strong>, lägg till URL-<strong>vägen</strong>, så i
            vårt exempel:
            <p>
              <code>/thank-you.php</code>
            </p>
            Du kan också välja <strong>Reguljärt uttryck</strong> som matchningstyp i rullgardinsmenyn.
            <br />
            Om du inte vet vad <strong>reguljära uttryck</strong> är: De är ett kraftfullt och praktiskt sätt att
            matcha strängar. Här kan du lära dig hur du använder dem på en timme eller två:{" "}
            <a href="https://regexone.com/" target="_blank" rel="noopener">
              RegexOne
            </a>
          </li>
          <li>
            Du kan valfritt tilldela ett <strong>värde</strong> till ditt mål. Jag skulle rekommendera det, om du kan tilldela ett
            numeriskt värde till varje visning av tack-sidan.
            <br /> Det finns flera sätt att göra detta. Det enklaste är att titta på den månatliga intäkten från dessa
            tack-sidor och dela den med det totala antalet visningar.
            <br />
            Det ger dig genomsnittet för varje gång målet nås.
          </li>
          <li>
            <strong>Ställ in en tratt</strong> om användarresan ska träffa vissa specifika sidor innan konverteringen. Detta är
            mycket meningsfullt för formulär som är uppdelade i flera steg. Du kan senare kontrollera om en andel av besökarna
            faller ur tratten någonstans under processen. <br />
            Du kan lägga till steg i tratten genom att ge varje steg ett <strong>namn</strong> och en{" "}
            <strong>sidväg</strong> (<em>se nedan</em>). <br />
            Observera att om du valde <strong>lika med</strong> i den övre destinationsrullgardinsmenyn, måste du använda samma
            matchningstyp för dina trattregler.
            <br />
            Eller om du valde <strong>reguljära uttryck</strong>, måste du följa regex-syntaxen i dina sidmatchningsregler.
          </li>
          <li>
            Innan du sparar, klicka på <strong>verifiera mål</strong>. <br />
            Om du redan har viss data i din GA-egendom, kommer den att gå igenom den och kontrollera efter matchningar i den
            historiska datan. <br />
            Om du inte har några matchningar, men vet att det borde finnas några, har du troligen gjort ett misstag vid
            specificeringen av sidvägarna.
            <br /> Detta är ett bra tillfälle att dubbelkolla att reglerna fungerar innan du slutför måluppsättningen.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-goals.png"
          alt="Google Analytics-inställningar för målsättning skärmdump"
          className="article-img"
        />

        <p>
          Grattis! Du har just ställt in ditt mål och kan spåra måluppfyllelser framöver! <br />
          Mål bör ställas in för de viktigaste konverteringarna på din webbplats. Om andra konverteringar är mindre
          kritiska eller kanske inte representeras med sin egen sidväg, kan du ställa in Google Analytics
          händelser.
          <br />
          Efter att ha ställt in en händelse kan du använda den för att basera dina måluppfyllelser på den.
        </p>

        <H as="h2">Ställ in händelser i Google Analytics</H>
        <p>
          Processen för <Link to="/se/händelsespårning">att ställa in händelser i Google Analytics</Link> kan se väldigt
          olika ut för varje händelse. Beroende på interaktionen och webbplatsens teknologi finns det olika steg att ta.
          Nedan kan du hitta en lista med olika exempel på händelsespårning.
        </p>
        <H as="h3">Exempel på händelsespårning</H>

        <ul>
          <li>dela ditt innehåll på sociala medier</li>
          <li>spela upp videon på din startsida</li>
          <li>trycka på tillbaka-knappen till Google-sökresultaten</li>
          <li>skrolla ner till botten av din artikel</li>
          <li>klicka på din kontaktmail</li>
          <li>lägga till en produkt i kundkorgen</li>
          <li>välja en färgvariant av en produkt</li>
        </ul>

        <p>
          Proceduren för att ställa in skrollspårning är annorlunda än för att spåra musklick. På dynamiska
          webbplatser är HTML-element inte alltid synliga. Därför skulle klickspårning kräva en annan metod
          än på statiska webbplatser. <br />
          Poängen är att det finns många påverkande faktorer, så jag skrev en separat artikel om händelsespårning.
          Där går jag in på detaljer, steg för steg, och förklarar hur man spårar knappklick eller andra element.
          Ta en titt på den här:
        </p>
        <p>
          <Link to="/se/händelsespårning">Händelsespårning i Google Analytics</Link>
        </p>
        <H as="h2">Ställ in kampanjspårning i Google Analytics</H>
        <p>
          Kampanjspårning är redan inbyggt i varje Google Analytics-uppsättning. Du behöver bara lägga till <Link to="/se/wiki-analytics/utm-parameter">UTM-parametrar</Link> till
          länkarna i dina e-post- eller sociala mediekampanjer.
        </p>
        <p>
          Det finns fem parametrar som du kan lägga till i vilken länk som helst som pekar på din webbplats. Den inbyggda Google
          Analytics-kampanjspårningen känner igen en användare från en sådan länk. Den tillskriver sedan besöket till den
          angivna kampanjen och du kan hitta det i kampanjrapporten.
        </p>
        <p>
          Låt oss ta URL:en <code>https://www.yoursite.com/campaign</code>
          <br />
          och följande kampanjspårningsparametrar som ett exempel:
        </p>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>UTM-parameter</th>
              <th>Parametervärde</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Source</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Gästinlägg</td>
            </tr>
            <tr>
              <td>UTM Campaign</td>
              <td>Vårförsäljning</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Stövlar</td>
            </tr>
            <tr>
              <td>UTM Content</td>
              <td>Stövlar när det är varmt ute</td>
            </tr>
          </tbody>
        </table>

        <p>
          Detta är <strong>samma URL inklusive UTM-parametrar</strong>:
        </p>
        <p>
          <code>
            https://www.yoursite.com/campaign?<strong>UTM_source</strong>
            =medium&amp;<strong>UTM_medium</strong>
            =guest-post&amp;<strong>UTM_campaign</strong>=spring-sales&amp;
            <strong>UTM_term</strong>=boots&amp;<strong>UTM_content</strong>
            =boots-when-its-warm-outside
          </code>
        </p>
        <p>
          Du kan använda den på sociala medier eller på andra källor som länkar till din webbplats. Google Analytics spårar
          automatiskt hur många klick som når dig från en sådan länk.
        </p>
        <p>
          Ser du de <strong>fem UTM-parametrarna</strong> i URL:en? Du behöver inte lägga till dem manuellt. Det finns{" "}
          <a
            href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
            target="_blank"
            title="Verktyg för att skapa länkar med UTM-parametrar"
          >
            UTM Builder-verktyg
          </a>{" "}
          som gör det åt dig. Du ger dem bara <strong>länken till din webbplats</strong> och värden för{" "}
          <strong>UTM-parametrarna</strong>. Därefter ger de dig kampanjspårningslänken, inklusive alla UTM-parametrar, tillbaka.
        </p>

        <H as="h2">Google Analytics Bästa Praxis</H>
        <p>
          Nedan är en lista över ytterligare <strong>bästa praxis</strong> för att ställa in Google Analytics. Jag tillämpar var och en noggrant
          för varje GA-uppsättning jag konfigurerar. De är inte bara <em>bra att ha</em>, utan snarare nödvändiga för att maximera
          datakvaliteten. <br />
          Filter kan också ställas in i tagghanteringssystemet. Faktum är att jag föredrar det, eftersom JavaScript möjliggör
          mer avancerade regler när man filtrerar. Filter i Google Analytics är dock enklare och gör nästan lika bra jobb.
        </p>
        <ol start="1">
          <li>
            <H as="h3">Exkludera egen trafik från Google Analytics</H>
            Nu när du spårar besök, exkludera dina egna sessioner från din webbplatstrafik. Ditt surfbeteende skiljer sig från vanliga användare och annars förvränger din data. Detta är särskilt viktigt för sidor med låg trafik, eftersom egna sidvisningar väger tyngre totalt sett.
            <br />
            För att <strong>exkludera din egen trafik</strong> kan du filtrera bort dig själv efter <strong>IP-adress</strong>,
            om du har en statisk IP.
            <br />
            Om inte, kanske du vill överväga att skaffa en från din internetleverantör, eftersom de vanligtvis inte kostar mycket.
          </li>
        </ol>
        <ul>
          <li>
            För att <strong>ställa in IP-exkludering i Google Analytics</strong>, gå till <strong>Administrationspanelen</strong> och
            klicka på <strong>Filter</strong> i <strong>vy</strong>-kolumnen till höger.
          </li>
          <li>
            Lägg till ett <strong>namn</strong> för ditt filter och välj <strong>filtertyp</strong> till{" "}
            <strong>anpassad</strong>.
          </li>
          <li>
            Välj alternativet <strong>exkludera</strong> filter och välj <strong>IP-adress</strong> i rullgardinsmenyn.
          </li>
          <li>
            Ange sedan din statiska IP i fältet <strong>filtermönster</strong> och klicka på <strong>spara</strong>.
          </li>
        </ul>

        <ImgContainerFixed width="415px">
          <ImgScreenshot
            src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
            alt="Exkludera IP från Google Analytics inställningsskärmdump"
            className="article-img"
          />
        </ImgContainerFixed>

        <ol start="2">
          <li>
            <H as="h3">Omvandla sidvägar till gemener</H>
            En annan justering är att automatiskt <strong>omvandla dina sidvägar till gemener</strong>.
            <br />
            Människor gör misstag och någon gång kommer någon att skapa en URL med stora bokstäver i dess namn. Om
            webbplatsen då returnerar samma innehåll, som för samma URL med små bokstäver, blir det rörigt.{" "}
            <br /> Låt mig förklara. <br />
            Vi skickar i princip data under två olika sidvägar, även om datan är för samma sida.
            <br />
            Sidvägar skrivna med olika bokstäver leder till rörig analys i Google Analytics. Varje unik skrivning av samma
            väg visas på en egen rad i rapporterna. Efter ett tag slutar du med att titta på flera rader och deras mätvärden, även om de borde kombineras. <br />
            Om du sedan också får bakåtlänkar till samma sida, men adressen i länken var skriven på ett roligt sätt,
            är röran perfekt. Så låt oss försöka hålla vårt liv dramafritt och fixa detta i förväg.
          </li>
        </ol>
        <ul>
          <li>
            För att <strong>omvandla URL-vägar till gemener</strong> besök <strong>Filter</strong> för din vy
            igen och lägg till ett nytt filter. Ge det ett namn och välj <strong>anpassad</strong> filtertyp.
          </li>
          <li>
            Välj nu <strong>Gemener</strong> och välj <strong>Request URI</strong> i fältets rullgardinsmeny.
          </li>
        </ul>

        <ImgContainerFixed width="369px">
          <ImgScreenshot
            src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
            alt="Ställ in gemener för URL:er i Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />

        <ul>
          <li>
            Klicka på <strong>spara</strong> för att avsluta. Enkel lösning som sparar många huvudvärkar.
          </li>
        </ul>
        <ol start="3">
          <li>
            <H as="h3">Google Analytics-vystruktur</H>
            <p>
              En <strong>vy</strong> är där du får tillgång till alla dina Google Analytics-rapporter. Om det finns filter inställda
              för din vy kommer alla rapporter att filtreras därefter.
              <br />
              Din <strong>vystruktur</strong> bör vanligtvis spegla din organisations struktur. Om dina målmarknader är t.ex. snarare
              regionala än landsbaserade, ställ in dina vyer på samma sätt. Det kommer att vara användbart när KPI:er för regioner
              är direkt tillgängliga.
              <br />
              En annan orientering är din organisations struktur för budgetering och marknadsföringsinsatser. Om det görs
              på landsnivå är det förmodligen en bra idé att följa den strukturen för vyerna också.
              <br />
              Du kan skapa upp till 25 vyer per egendom. Så, beroende på antalet marknader och marknadsföringskanaler, är flera
              vystrukturer möjliga.
            </p>
            <H as="h3">Exempel: Google Analytics-vystruktur för lokala webbplatser</H>
            <p>
              1.0 Master
              <br />
              2.0 Intern trafik
              <br />
              3.0 Rådata
            </p>
            <p>
              På ett eller annat sätt finns det vanligtvis 3 typer av vyer. De kan filtreras ytterligare efter land
              eller enhet, om det är vettigt för din organisation.
            </p>
            <H as="h3">Exempel: Google Analytics-vystruktur för multinationella webbplatser</H>
            <p>
              1.1 Master (US)
              <br />
              1.2 Master (UK)
              <br />
              1.3 Master (DE)
              <br />
              1.4 Master (FR)
              <br />
              1.5 Master (ES)
              <br />
              1.6 Master (MX)
              <br />
              1.7 Master (RUS)
              <br />
              2.0 Intern trafik
              <br />
              3.0 Rådata
            </p>

            <H as="h3">Exempel: Google Analytics regional vystruktur</H>
            <p>
              1.0 Master (NA)
              <br />
              1.1 Master (EMEA)
              <br />
              1.2 Master (LATAM)
              <br />
              1.3 Master (APEC)
              <br />
              2.0 Intern trafik
              <br />
              3.0 Rådata
            </p>

            <p>
              <strong>Mastervyn</strong> är huvudvyn för analys och exkluderar all intern trafik.
            </p>
            <p>
              <strong>Intern trafikvy</strong> inkluderar all intern trafik. Denna kan användas för felsökning och testning också. Om du ställer in inklusionsfiltret kommer allt annat automatiskt att exkluderas.
            </p>
            <p>
              I början skapar Google Analytics en första <strong>All Web Site Data-vy</strong> tillsammans med GA-egendomen.
            </p>
            <p>
              Behåll den, men ändra namnet till <strong>Rådata</strong>. Du vill ha en rådatavy utan några filter. Du kan inte ångra ett filter retroaktivt och titta på ofiltrerad data. Därför är det en bästa praxis att behålla en vy oförändrad. För att ändra namnet, gå till <strong>Administratör &gt; Vy &gt; Vyinställningar</strong>.
            </p>
            <p>
              I de flesta fall fokuserar företag sina insatser på ett eller några få länder. I så fall, skapa vyer på landbasis och ställ in filter därefter.
              <br />
              Du kan också skapa vyer per trafikkälla (organisk, betald, direkt, social, etc.) eller enhet (dator, surfplatta, mobil). Jag använder Google Analytics-segment för det, men det finns ingen anledning att inte filtrera specifika detaljer redan i vyn. Jag gillar att hålla antalet vyer lågt för att hålla GA-användargränssnittet rent.
              <br />Jag kanske blir lite rörigare med segment i vyn och tillämpar återstående specifika detaljer där.
            </p>
            <p>
              Skapa alla vyer så tidigt som möjligt, eftersom de bara visar data från det ögonblick de skapades. En ny vy kommer inte att visa någon historisk data från före skapelsedatumet. Om du behöver titta tillbaka i historien med specifika filter, faller du tillbaka på rådatavyn. Du tillämpar bara de avsedda filtren med segment istället. På detta sätt får du din insikt, men förändrar inte datan.
            </p>
            <p>
              <strong>TIPS</strong>: Om några vyer delar samma filter kan du skapa en och sedan duplicera den tillsammans med alla filter. Därefter lägger du bara till vy-specifika filter till kopiorna.
              <br />
              Se till att hålla originalvyn oförändrad så att du bara lägger till filter till kopiorna. Att ändra originalvyn senare påverkar även kopierade vyer.
            </p>
          </li>
          <li>
            <H as="h3">Google Analytics-varningar</H>
            En annan bästa praxis är att <strong>ställa in Google Analytics-varningar</strong>. Jag ställer in det för att få meddelanden vid minskningar i sessioner eller när serverns svarstid ökar. På detta sätt kan jag undersöka orsakerna och potentiellt göra något.
          </li>
        </ol>
        <ul>
          <li>
            För att ställa in en varning i Google Analytics, besök <strong>Anpassning</strong> i sidmenyn.
          </li>
          <li>
            Alternativt kan du också besöka <strong>Administratör</strong> igen och klicka på <strong>Anpassade varningar</strong> i vyns panel.
          </li>
          <li>
            Klicka på <strong>Anpassade varningar</strong> och klicka på <strong>hantera anpassade varningar</strong>.
          </li>
          <li>
            Att konfigurera en Google Analytics-varning för minskande sessioner är ganska enkelt. Klicka på <strong>Ny varning</strong> och namnge varningen till exempel <strong>Sessionsminskning</strong> med <strong>Period</strong> inställd på <strong>Vecka</strong>.
          </li>
          <li>Markera också rutan för att få ett e-postmeddelande när varningen utlöses.</li>
          <li>
            Det finns vanligtvis trafikfluktuationer mellan vardagar, helger och nationella helgdagar. Vi vill inte bli varnade för dessa fluktuationer, så vi ställer in det för att titta på veckovis sessionsdata.
          </li>
          <li>
            <strong>Varningsvillkor</strong> gäller för <strong>all trafik</strong> och du vill bli varnad när <strong>sessionerna minskar med mer än 35% jämfört med föregående vecka.</strong>
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
          alt="Ställ in Google Analytics-varningar för sessionsminskning skärmdump"
          className="article-img"
        />

        <p>
          Därefter, klicka på <strong>spara</strong> och låt oss lägga till nästa varning.
        </p>
        <p>För att ställa in en Google Analytics-varning för ökande serverns svarstid,</p>
        <ul>
          <li>
            klicka på <strong>Ny varning</strong> och ge den ett namn, som{" "}
            <em>Genomsnittlig serverns svarstid ökar dag för dag</em> och
          </li>
          <li>
            välj <strong>dag</strong> som period.
          </li>
          <li>
            För <strong>varningsvillkoren</strong> gäller det <strong>all trafik</strong> och du vill bli
            varnad när din <strong>genomsnittliga serverns svarstid ökar med mer än 300%</strong> jämfört med{" "}
            <strong>föregående dag</strong>:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
          alt="ställ in Google Analytics-varningar för serverns svarstid skärmdump"
          className="article-img"
        />

        <p>
          Därefter, klicka på <strong>spara</strong>.<br />
          Nu kan du vara säker på att få ett varningsmail när din trafik minskar eller när din server har problem.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsSetup;
